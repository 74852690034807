import { IDialogType } from "./IDialogType";
import {UserRoles} from "../utils/userRoles.ts";

export type EditRequestValue = string | string[] | UserSchedule[]

export interface EditRequest {
    field: string,
    acceptedAt: Date | null,
    rejectedAt: Date | null,
    value: EditRequestValue,
}

export const enum EWeekDays {
    PN = 'pn',
    VT = 'vt',
    SR = 'sr',
    CT = 'ct',
    PT = 'pt',
    SB = 'sb',
    VS = 'vs',
}

export interface UserSchedule {time: string, days: EWeekDays[]}

export enum EUserLanguages {
    RUS = "russian",
    ARM = "armenian",
    ENG = "english",
    FRA = "french",
    GER = "german",
    HINDI = 'hindi',
    SPA = 'spanish',
    ITA = 'italian',
    OTHER = 'other'
}

export enum ELessonFormatTypes  {
    GROUP = 'group',
    IND = 'ind',
    GROUPIND = 'groupind',

}

export enum ECountries {
    ARMENIA = "Armenia",
    RUSSIA = "Russia",
    EUROPA = "Europe",
    AMERICA = "America",
    CANADA = "Canada",
}

export interface User {
    _id: string;
    email: string;
    name: string;
    sname: string;
    tname: string;
    avatar?: string;
    role: UserRoles;
    online?: boolean;
    lastOnline: Date;
    dialog_types?: IDialogType[];
    lichess?: string;
    requizits?: number;
    born?: Date;
    country?: ECountries;
    sex?: string;
    fcm?: string[]
    archive?: boolean;
    seance?: boolean;
    offline?: boolean;
    groups?: [{_id: string, name: string}]
    shedule?: UserSchedule[]
    format?: ELessonFormatTypes,
    durency?: string,
    comment?:string,
    parentName?: string,
    actualMail?: string,
    nationality?: string,
    languages?: EUserLanguages[],
    city?: string,
    whatsappNumber?: string,
    editRequest?: EditRequest[],
}