import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import OnlyUnauthorized from './components/Auth/OnlyUnauthorized';
import RequireAuth from './components/Auth/RequireAuth';
import RequireRole from './components/Auth/RequireRole';
import FAQAdaptive from './components/FAQ/FAQAdaptive.tsx';
import LayoutAdaptive from './components/Layouts/LayoutAdaptive.jsx';
import LayoutMessenger from './components/Layouts/MessengerAdaptive.tsx';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import BalancePage from './pages/BalancePage';
import CabinetPage from './pages/CabinetPage/CabinetPage.tsx';
import Lesson from './pages/Lessons/Lesson';
import Lessons from './pages/Lessons/Lessons';
import LoginPage from './pages/LoginPage';
import ChatPage from './pages/Messenger/ChatPage';
import MessengerPage from './pages/Messenger/MessengerPage';
import DescriptionPage from './pages/MyGroups/DescriptionPage';
import Group from './pages/MyGroups/Group';
import HistoryPage from './pages/MyGroups/HistoryPage';
import MyGroups from './pages/MyGroups/MyGroups';
import OnlineLesson from './pages/MyGroups/OnlineLesson';
import PermissionsPage from './pages/PermissionsPage';
import PolicyPage from './pages/PolicyPage.tsx';
import ProgramPage from './pages/ProgramPage';
import RegisterPage from './pages/RegisterPage';
import Room from './pages/Room';
import TermsPage from './pages/TermsPage.tsx';
import { ChatRoomNotificationSocket } from './sockets/MessengerSockets';
import { socket } from './sockets/socket';
import {
  delMessage,
  incUnreaded,
  pushMessage,
  updateChat,
} from './store/reducers/MessengerSlice';
import { initLanguage } from './store/reducers/TranslateSlice.ts';
import { checkAuth, userSlice } from './store/reducers/UserSlice';
import { saveTokenFCM } from './utils/fcms.ts';
import EditRequestsResults from './components/Modals/EditRequestsResults.tsx';
import { getCounters } from './store/reducers/PermissionsSlice.ts';
import { isUserDirector, UserRoles } from './utils/userRoles.ts';
import { isDeviceMobile } from './utils/getDeviceType.ts';
import CreateSessionPage from './pages/MyGroups/Sessions/CreateSessionPage.tsx';
import CreatNewSessionPage from './pages/MyGroups/Sessions/CreatNewSessionPage.tsx';
import SessionTablePage from './pages/MyGroups/Sessions/SessionTablePage.tsx';
import SessionGamePage from './pages/MyGroups/Sessions/SessionGamePage.tsx';
import SeansToStartPage from './pages/MyGroups/Sessions/SeansToStartPage.tsx';
import SessionGamesPage from './pages/MyGroups/Sessions/SessionGamesPage.tsx';
import CreateSeansDirectorPage from './pages/MyGroups/Sessions/CreateSeansDirectorPage.tsx';
import MyComments from './pages/MyComments/MyComments.tsx';
import Comments from './pages/Comments/Comments.tsx';
import ForbiddenPage from './pages/ForbiddenPage.tsx';
import RequireNotArchivedStudent from './components/Auth/RequireNotArchivedStudent.tsx';

const IndexPage = React.lazy(() => import('./pages/IndexPage'));

const UserCabinetPage = React.lazy(() => import('./pages/UserCabinetPage'));
const AddHomeworkPage = React.lazy(
  () => import('./pages/Homework/AddHomeworkPage'),
);
const HomeworksPage = React.lazy(
  () => import('./pages/Homework/HomeworksPage'),
);
const HomeworkPage = React.lazy(() => import('./pages/Homework/HomeworkPage'));
const HomeworksCheckPage = React.lazy(
  () => import('./pages/MyGroups/HomeworksCheckPage'),
);
const HomeworkCheckPage = React.lazy(
  () => import('./pages/MyGroups/HomeworkCheckPage'),
);
const VideoPage = React.lazy(() => import('./pages/Video/VideoPage'));
const VideoListPage = React.lazy(() => import('./pages/Video/VideoListPage'));
const FAQTrainersPage = React.lazy(() => import('./pages/FAQTrainersPage'));
const CreateTestLesson = React.lazy(
  () => import('./pages/TestLesson/CreateTestLesson'),
);
const TestLesson = React.lazy(() => import('./pages/TestLesson/TestLesson'));
const GroupwaitingPage = React.lazy(() => import('./pages/GroupwaitingPage'));
const GroupwaitingArchivePage = React.lazy(
  () => import('./pages/GroupwaitingArchivePage'),
);

function App() {
  const { isLoading, user, isAuth } = useAppSelector(
    (state) => state.UserSlice,
  );
  const countersRecived = useAppSelector(
    (state) => state.PermissionsSlice.countersRecived,
  );
  const navigate = useNavigate();
  const { storeLoad } = userSlice.actions;
  const dispatch = useAppDispatch();
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const tokenFCM = searchParams.get('token_fcm') || searchParams.get('fcm_token');

  useEffect(() => {
    dispatch(initLanguage());
    if (
      !isLoading &&
      user.role &&
      user.role !== UserRoles.STUDENT &&
      isDeviceMobile()
    ) {
      navigate('/messenger');
    }
  }, [isAuth]);

  useEffect(() => {
    if (tokenFCM) {
      localStorage.setItem('tokenFCM', tokenFCM);
      saveTokenFCM(tokenFCM).catch((error) => console.error(error));
    }
  }, [tokenFCM]);

  useEffect(() => {
    const CheckLogin = async () => {
      if (localStorage.getItem('token')) {
        await dispatch(checkAuth());
      } else {
        dispatch(storeLoad());
      }
    };
    CheckLogin().catch(console.error);
  }, [dispatch, storeLoad]);

  useEffect(() => {
    if (user._id) {
      ChatRoomNotificationSocket(user._id);
    }

    if (user && isUserDirector(user.role) && !countersRecived) {
      void dispatch(getCounters());
    }
  }, [user, countersRecived]);

  useEffect(() => {
    const reciveHandler = async (data: { to: { _id: string } }) => {
      dispatch(incUnreaded());
      dispatch(updateChat(data));

      const audio = new Audio('/message.wav');
      if (
        !user.dialog_types?.find(
          (type) => type.dialog === data.to._id && type.name === 'archive',
        )
      ) {
        await audio.play();
      }
    };

    socket.on('message:recive', (data) => {
      dispatch(pushMessage(data));
    });
    socket.on('notification:recive', reciveHandler);
    socket.on('message:delete_recive', (data) => {
      dispatch(delMessage(data));
    });

    return () => {
      socket.off('notification:recive', reciveHandler);
    };
  }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isLoading) {
    return (
      <>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <LayoutAdaptive>
                  <Suspense>
                    <IndexPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireAuth>
            }
          />
          <Route
            path="/mygroups"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'TRANER', 'ADMIN']}>
                <LayoutAdaptive>
                  <MyGroups />
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          {/* <Route
            path="/mycomments"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'TRANER', 'ADMIN']}>
                <LayoutAdaptive>
                  <MyComments />
                </LayoutAdaptive>
              </RequireRole>
            }
          /> */}
          <Route
            path="/comments"
            element={
              <RequireRole
                roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'ADMIN']}
              >
                <LayoutAdaptive>
                  <Comments />
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/group/:groupId"
            element={
              <RequireAuth>
                <LayoutAdaptive>
                  <Group />
                </LayoutAdaptive>
              </RequireAuth>
            }
          />
          <Route
            path="/group/:groupId/program"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'ADMIN', 'TRANER']}>
                <LayoutAdaptive>
                  <ProgramPage />
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/group/:groupId/online-lesson"
            element={
              <RequireAuth>
                <LayoutMessenger>
                  <OnlineLesson />
                </LayoutMessenger>
              </RequireAuth>
            }
          />
          <Route
            path="/group/:groupId/history"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'ADMIN', 'TRANER']}>
                <LayoutAdaptive>
                  <HistoryPage />
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/group/:groupId/description"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'ADMIN', 'TRANER']}>
                <LayoutAdaptive>
                  <DescriptionPage />
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/group/:groupId/homework/add"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'TRANER']}>
                <LayoutAdaptive>
                  <Suspense>
                    <AddHomeworkPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/group/:groupId/homework/edit/:homeworkId"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'TRANER']}>
                <LayoutAdaptive>
                  <Suspense>
                    <AddHomeworkPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/group/:groupId/homework"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'TRANER']}>
                <LayoutAdaptive>
                  <Suspense>
                    <HomeworksCheckPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/group/:groupId/homework/:homeworkId"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'TRANER']}>
                <LayoutAdaptive>
                  <Suspense>
                    <HomeworkCheckPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/homework"
            element={
              <RequireRole
                roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'STUDENT']}
              >
                <LayoutAdaptive>
                  <Suspense>
                    <HomeworksPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/homework/:groupId"
            element={
              <RequireRole
                roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'STUDENT']}
              >
                <LayoutAdaptive closedmenu={true}>
                  <Suspense>
                    <HomeworkPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/video"
            element={
              <RequireRole
                roles={[
                  'STUDENT',
                  'DIRECTOR',
                  'ZDIRECTOR',
                  'TRANER',
                  'TRANERMETODIST',
                ]}
              >
                <RequireNotArchivedStudent>
                  <LayoutAdaptive>
                    <Suspense>
                      <VideoPage />
                    </Suspense>
                  </LayoutAdaptive>
                </RequireNotArchivedStudent>
              </RequireRole>
            }
          />
          <Route
            path="/video/:groupId"
            element={
              <RequireRole
                roles={[
                  'STUDENT',
                  'DIRECTOR',
                  'ZDIRECTOR',
                  'TRANER',
                  'TRANERMETODIST',
                ]}
              >
                <RequireNotArchivedStudent>
                  <LayoutAdaptive>
                    <Suspense>
                      <VideoListPage />
                    </Suspense>
                  </LayoutAdaptive>
                </RequireNotArchivedStudent>
              </RequireRole>
            }
          />

          <Route
            path="/lessons"
            element={
              <RequireRole
                roles={['STUDENT', 'DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST']}
              >
                <RequireNotArchivedStudent>
                  <LayoutAdaptive>
                    <Lessons />
                  </LayoutAdaptive>
                </RequireNotArchivedStudent>
              </RequireRole>
            }
          />
          <Route
            path="/lesson/:groupId"
            element={
              <RequireRole
                roles={['STUDENT', 'DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST']}
              >
                <RequireNotArchivedStudent>
                  <LayoutAdaptive closedmenu={true}>
                    <Lesson />
                  </LayoutAdaptive>
                </RequireNotArchivedStudent>
              </RequireRole>
            }
          />
          <Route
            path="/permissions"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'ADMIN']}>
                <LayoutAdaptive>
                  <PermissionsPage />
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/program"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR']}>
                <LayoutAdaptive>
                  <ProgramPage />
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/login"
            element={
              <OnlyUnauthorized>
                <LoginPage />
              </OnlyUnauthorized>
            }
          />
          <Route
            path="/register"
            element={
              <OnlyUnauthorized>
                <RegisterPage />
              </OnlyUnauthorized>
            }
          />
          <Route
            path="/messenger"
            element={
              <RequireAuth>
                <RequireNotArchivedStudent>
                  <LayoutMessenger>
                    <MessengerPage />
                  </LayoutMessenger>
                </RequireNotArchivedStudent>
              </RequireAuth>
            }
          />
          <Route
            path="/messenger/chat/:userid"
            element={
              <RequireAuth>
                <RequireNotArchivedStudent>
                  <LayoutMessenger>
                    <ChatPage />
                  </LayoutMessenger>
                </RequireNotArchivedStudent>
              </RequireAuth>
            }
          />
          <Route path="/room/:roomID" element={<Room />} />
          <Route
            path="/balance"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'STUDENT']}>
                <LayoutAdaptive>
                  <BalancePage />
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <LayoutAdaptive>
                  <CabinetPage />
                </LayoutAdaptive>
              </RequireAuth>
            }
          />
          <Route
            path="/profile/:userId"
            element={
              <RequireAuth>
                <LayoutAdaptive>
                  <Suspense>
                    <UserCabinetPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireAuth>
            }
          />

          <Route
            path="/testlesson"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST']}>
                <LayoutAdaptive>
                  <Suspense>
                    <CreateTestLesson />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/testlesson/:groupId"
            element={
              <LayoutAdaptive>
                <Suspense>
                  <TestLesson />
                </Suspense>
              </LayoutAdaptive>
            }
          />

          <Route
            path="/session/:groupId"
            element={
              <RequireAuth>
                <RequireRole roles={['TRANER', 'TRANERMETODIST']}>
                  <LayoutAdaptive>
                    <CreateSessionPage />
                  </LayoutAdaptive>
                </RequireRole>
              </RequireAuth>
            }
          />
          <Route
            path="/session"
            element={
              <RequireAuth>
                <LayoutAdaptive>
                  <CreateSeansDirectorPage />
                </LayoutAdaptive>
              </RequireAuth>
            }
          />
          <Route
            path="/session/:groupId/create"
            element={
              <RequireAuth>
                <RequireRole
                  roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'TRANER']}
                >
                  <LayoutAdaptive>
                    <CreatNewSessionPage />
                  </LayoutAdaptive>
                </RequireRole>
              </RequireAuth>
            }
          />
          <Route
            path="/session/table"
            element={
              <RequireAuth>
                <RequireRole
                  roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'TRANER', 'STUDENT']}
                >
                  <LayoutAdaptive>
                    <SessionTablePage />
                  </LayoutAdaptive>
                </RequireRole>
              </RequireAuth>
            }
          />
          <Route
            path="/session/tostart/:seansId"
            element={
              <RequireAuth>
                <RequireRole
                  roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'TRANER', 'STUDENT']}
                >
                  <LayoutAdaptive>
                    <SeansToStartPage />
                  </LayoutAdaptive>
                </RequireRole>
              </RequireAuth>
            }
          />
          <Route
            path="/session/game/:seansId"
            element={
              <RequireAuth>
                <RequireRole
                  roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'TRANER', 'STUDENT']}
                >
                  <LayoutAdaptive>
                    <SessionGamePage />
                  </LayoutAdaptive>
                </RequireRole>
              </RequireAuth>
            }
          />
          <Route
            path="/session/game/s"
            element={
              <RequireAuth>
                <RequireRole
                  roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'TRANER']}
                >
                  <LayoutAdaptive>
                    <SessionGamesPage />
                  </LayoutAdaptive>
                </RequireRole>
              </RequireAuth>
            }
          />

          <Route
            path="/faq"
            element={
              <RequireRole
                roles={['DIRECTOR', 'ZDIRECTOR', 'TRANERMETODIST', 'STUDENT']}
              >
                <LayoutAdaptive>
                  <Suspense>
                    <FAQAdaptive />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/faqtrainers"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'TRANER']}>
                <LayoutAdaptive>
                  <Suspense>
                    <FAQTrainersPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/groupwaiting"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'ADMIN']}>
                <LayoutAdaptive>
                  <Suspense>
                    <GroupwaitingPage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/groupwaiting/archive"
            element={
              <RequireRole roles={['DIRECTOR', 'ZDIRECTOR', 'ADMIN']}>
                <LayoutAdaptive>
                  <Suspense>
                    <GroupwaitingArchivePage />
                  </Suspense>
                </LayoutAdaptive>
              </RequireRole>
            }
          />
          <Route
            path="/policy"
            element={
              <Suspense>
                <PolicyPage />
              </Suspense>
            }
          />
          <Route
            path="/terms"
            element={
              <Suspense>
                <TermsPage />
              </Suspense>
            }
          />
          <Route
            path="/forbidden"
            element={
              <Suspense>
                <ForbiddenPage />
              </Suspense>
            }
          />
        </Routes>
        <EditRequestsResults />
      </>
    );
  }
}

export default App;
